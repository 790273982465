import React from "react"
import {graphql} from "gatsby"
import {GatsbyImage, getImage} from "gatsby-plugin-image";


const PageTemplate = ({data}) => {
    const page = data.markdownRemark
    return (
        <div>
            <div className="nav-background"/>
            <div className="container mt-5">
                <div dangerouslySetInnerHTML={{__html: page.html}}/>
                {page.frontmatter.images &&
                <div className="row  justify-content-evenly mt-5 text-center">
                    {page.frontmatter.images.map(image =>
                        <figure className="figure col-4" key={image.id}>
                            <GatsbyImage alt={image.id} image={getImage(image.childImageSharp)} className="figure-img img-fluid rounded"/>
                        </figure>
                    )}
                </div>
                }
            </div>
        </div>
    )
};

export const query = graphql`
    query($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
                images {
                    id
                    childImageSharp {
                        gatsbyImageData(
                            aspectRatio: 1.5
                            placeholder: BLURRED
                        )
                    }
                }
            }
        }
    }
`

export default PageTemplate;